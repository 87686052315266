<template>
  <div>
    <div v-if="!loading">
      <v-card
        flat
        style="background-color: var(--v-background-base) !important"
      >
        <v-toolbar
          :class="
            !$vuetify.breakpoint.mobile ? 'toolbarHead' : 'toolbarHeadMobile'
          "
          color="greyBase"
          dark
          flat
          dense
          height="30px"
        >
          <v-toolbar-title
            v-if="!$vuetify.breakpoint.mobile"
            style="font-size: 16px"
          >
            <v-btn icon @click="$router.go(-1)" class="mr-2 ml-1">
              <v-icon>arrow_back</v-icon>
            </v-btn>
            <b class="mr-3">
              <v-avatar
                size="32"
                v-if="customerSetting.relatedOrganisation.logo"
                color="white"
                class="mr-2"
                ><v-img
                  contain
                  :src="customerSetting.relatedOrganisation.logo"
                ></v-img
              ></v-avatar>
              {{ customerSetting.relatedOrganisation.name }}</b
            >
          </v-toolbar-title>
        </v-toolbar>
        <div class="contents">
          <div class="mainContentWrap">
            <v-tabs height="30px" v-model="activeTab" class="tabby">
              <v-tab
                v-for="tab in tabs"
                :key="tab.value"
                :value="tab.value"
                :href="`#${tab.value}`"
                style="font-size: 12px"
              >
                <span>{{ tab.name }}</span>
              </v-tab>
            </v-tabs>
            <div class="mainContent">
              <v-tabs-items
                v-model="activeTab"
                style="height: inherit; width: 100%"
              >
                <v-tab-item
                  style="height: inherit; width: 100%"
                  value="profile"
                >
                  <CompanyProfile
                    :company="customerSetting"
                    :teams="customerSetting.relationshipTeams"
                    @refresh="refresh"
                    @refreshTeams="refreshTeams"
                  />
                </v-tab-item>

                <v-tab-item
                  style="height: inherit; width: 100%"
                  value="shipmentProfiles"
                >
                  <ShipmentProfile
                    :selectedCustomer="customerSetting"
                    :bookingScope="bookingScope"
                    :shipmentScope="shipmentScope"
                  />
                </v-tab-item>

                <v-tab-item
                  style="height: inherit; width: 100%"
                  value="shipments"
                >
                  <Shipments :selectedCustomer="customerSetting" />
                </v-tab-item>

                <v-tab-item
                  style="height: inherit; width: 100%"
                  value="relationshipSettings"
                >
                  <Setting
                    :company="customerSetting"
                    :teams="customerSetting.relationshipTeams"
                    @refreshTeams="refreshTeams"
                  />
                </v-tab-item>

                <v-tab-item
                  style="height: inherit; width: 100%"
                  value="financialSettings"
                >
                  <FinancialSettings
                    :company="customerSetting"
                    :teams="customerSetting.relationshipTeams"
                    @updatePaymentTerms="updatePaymentTerms"
                    @refreshTeams="refreshTeams"
                  />
                </v-tab-item>
              </v-tabs-items>
            </div>
          </div>
        </div>
      </v-card>
    </div>
    <div
      v-else
      style="height: 90vh; padding-top: 0; margin-top: 0"
      class="text-center"
    >
      <valhalla-loading :showFog="true" />
    </div>
  </div>
</template>
<script>
import CompanyProfile from "@/components/Contacts/CompanyProfile.vue";
import FinancialSettings from "../components/Contacts/FinancialSettings.vue";
// import PackWeight from '@/components/Contacts/PackWeight.vue'
// import Dashboard from '@/components/Contacts/Dashboard.vue'
// import Roles from '@/components/Contacts/Roles.vue'
import Setting from "@/components/Contacts/Setting.vue";
import Shipments from "@/components/Contacts/Shipment.vue";
import ShipmentProfile from "@/components/Contacts/ShipmentProfile.vue";
// import DocumentSharing from '../components/Contacts/DocumentSharing.vue'

export default {
  components: {
    CompanyProfile,
    FinancialSettings,
    // Dashboard,
    // PackWeight,
    // Roles,
    Setting,
    Shipments,
    ShipmentProfile,
    // DocumentSharing,
  },
  data: () => ({
    activeTab: "profile",
    bookingScope: null,
    customerSetting: {},
    loading: false,
    scope: null,
    shipmentScope: null,
    tabs: [
      {
        name: "Profile",
        value: "profile",
      },
      {
        name: "Shipments",
        value: "shipments",
      },
      {
        name: "Shipment Profiles",
        value: "shipmentProfiles",
      },
      {
        name: "Relationship Settings",
        value: "relationshipSettings",
      },
      {
        name: "Financial Settings",
        value: "financialSettings",
      },
      // {
      //     name: 'Pack Weight Management',
      //     value: 'packWeightManagement'
      // }
    ],
  }),
  watch: {
    "$route.params.id": {
      immediate: true,
      handler(val) {
        if (val) this.getCustomerSetting(val);
      },
    },
  },
  async mounted() {
    this.bookingScope = await this.$API.authoriseScope({
      type: "booking",
    });
    this.shipmentScope = await this.$API.authoriseScope({
      type: "shipment",
    });
    //   console.log(this.scope)
  },
  methods: {
    async getCustomerSetting(id) {
      try {
        this.loading = true;
        this.customerSetting = await this.$API.getSelectedCustomerRelationship(
          id
        );
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    refresh(event) {
      this.customerSetting = event;
    },
    async refreshTeams() {
      this.customerSetting.relationshipTeams =
        await this.$API.getRelationshipTeam(this.$route.params.id);
      console.log("Teams", this.customerSetting.relationshipTeams);
    },
    updatePaymentTerms(paymentTerms) {
      console.log("updating payment terms with: ");
      console.log(paymentTerms);
      this.customerSetting.paymentTerms = { ...paymentTerms };
    },
  },
};
</script>
<style scoped>
* {
  --gutters: 0.1rem;
  --toolBarHeight: 36px;
  --tabHeight: 30px;
  --darkLayer: var(--v-greyDarker-base) !important;
}

.topBar {
  height: var(--toolBarHeight);
  width: 100%;
  display: flex;
  align-items: center;
  background-color: var(--v-greyDarker-base) !important;
  background: var(--v-greyDarker-base) !important;
}
.contents {
  display: flex;
  width: 100%;
  height: calc(100vh - var(--toolBarHeight));
  background: var(--v-greyRaised-base) !important;
}
.mainContentWrap {
  background: var(--v-greyRaised-base) !important;
  height: 100%;
  min-height: 100%;
  width: 100%;
  min-width: 50%;
  max-width: 100%;
  position: relative;
}
.mainContentTabs {
  height: var(--tabHeight) !important;
}
.mainContent {
  max-height: calc(100vh - var(--toolBarHeight) - var(--tabHeight));
  height: 100%;
  width: 100%;
}
.tabby ::v-deep.theme--dark.v-tabs .v-tabs-bar,
.tabby ::v-deep .v-tabs-bar__content,
.tabby ::v-deep .v-tab {
  background: var(--v-greyDarker-base) !important;
  border-radius: 0px;
}
</style>