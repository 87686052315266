<template>
  <div style="background: var(--v-component-base) !important">
    <v-card flat>
      <v-card-text class="my-0 py-0">
        <v-toolbar flat dense>
          <span class="mr-2">Selected Season: </span>
          <v-chip
            v-for="(year, index) in availableYears"
            :key="index"
            class="mx-1"
            label
            :color="
              params.selectedYear == year.value ? 'secondary' : 'grey darken-2'
            "
            @click="selectYear(year.value)"
            >{{ year.name }}</v-chip
          >
        </v-toolbar>

        <v-row>
          <v-col cols="12" sm="3">
            <v-card outlined flat style="border-radius: 20px" class="py-0 my-0">
              <v-card-text class="py-0 my-0">
                <v-list-item>
                  <v-list-item-action class="mx-0">
                    <v-icon color="green">{{
                      getStatusIcon("Arrived")
                    }}</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title> Arrived </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      <v-icon small color="grey" class="mr-1">widgets</v-icon>
                      {{ filteredContainers("Arrived") }} containers
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      <v-icon small color="grey" class="mr-1">list_alt</v-icon>
                      {{ filteredPallets("Arrived") }} Pallets
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="3">
            <v-card outlined flat style="border-radius: 20px" class="py-0 my-0">
              <v-card-text class="py-0 my-0">
                <v-list-item>
                  <v-list-item-action class="mx-0">
                    <v-icon color="blue">{{
                      getStatusIcon("In Transit")
                    }}</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title> In Transit </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      <v-icon small color="grey" class="mr-1">widgets</v-icon>
                      {{ filteredContainers("In Transit") }}
                      containers
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      <v-icon small color="grey" class="mr-1">list_alt</v-icon>
                      {{ filteredPallets("In Transit") }} Pallets
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="3">
            <v-card outlined flat style="border-radius: 20px" class="py-0 my-0">
              <v-card-text class="py-0 my-0">
                <v-list-item>
                  <v-list-item-action class="mx-0">
                    <v-icon color="orange">{{
                      getStatusIcon("Awaiting Departure")
                    }}</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title> Awaiting Departure </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      <v-icon small color="grey" class="mr-1">widgets</v-icon>
                      {{ filteredContainers("Awaiting Departure") }}
                      containers
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      <v-icon small color="grey" class="mr-1">list_alt</v-icon>
                      {{ filteredPallets("Awaiting Departure") }}
                      Pallets
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              style="max-width: 400px"
              outlined
              dense
              rounded
              prefix-inner-icon="search"
              placeholder="Search"
              clearable
              v-model="search"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="mt-0 pt-0">
            <v-data-table
              id="shipment-table"
              ref="table"
              :key="filterKey"
              disable-sort
              disable-pagination
              dense
              hide-default-footer
              hide-default-header
              :loading="loading"
              class="mt-0 pt-0"
              :group-by="'position'"
              @click:row="viewShipment"
              :items="filteredDetails"
              height="76vh"
              :headers="shipmentHeaders"
              style="font-size: 11px; cursor: pointer"
              fixed-header
            >
              <template #header="{ props: { headers } }">
                <thead class="v-data-table-header">
                  <tr>
                    <th
                      scope="col"
                      v-for="(head, index) in headers"
                      :key="index"
                      style="
                        font-size: 12px;
                        color: grey;
                        border-bottom: 1px solid grey;
                        padding-top: 10px;
                      "
                    >
                      <div class="text-center">
                        {{ head.text
                        }}<v-badge
                          v-if="head.filterable"
                          style="z-index: 1000"
                          :content="
                            filters[head.value]
                              ? filters[head.value].length
                              : false
                          "
                          :value="
                            filters[head.value] &&
                            filters[head.value].length > 0
                          "
                          color="primary"
                          offset-y="10"
                        >
                          <v-menu offset-y left :close-on-content-click="false">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                small
                                class="ml-1"
                                icon
                                ><v-icon small>filter_alt</v-icon></v-btn
                              >
                            </template>
                            <v-list dense>
                              <v-list-item
                                v-for="(item, index) in headerOptions(
                                  head.value
                                )"
                                :key="index"
                              >
                                <v-list-item-action class="pr-0">
                                  <v-checkbox
                                    v-model="filters[head.value]"
                                    :value="item.value"
                                    hide-details
                                    dense
                                    @change="calculateFilteredResults()"
                                  ></v-checkbox>
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    {{ item.name }}
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </v-badge>
                      </div>
                    </th>
                  </tr>
                </thead>
              </template>

              <template v-slot:[`group.header`]="{ items, isOpen, toggle }">
                <th colspan="3">
                  <v-icon @click="toggle" small class="mr-1"
                    >{{ isOpen ? "remove" : "add" }}
                  </v-icon>
                  <v-chip small class="mx-1">{{ items.length }}</v-chip>
                  <span>{{ items[0].vesselVoyageName }}</span>
                </th>
                <!-- <th colspan="4" class="text-left" style="color: grey">
                  <v-chip small outlined style="border: none">
                    <v-chip x-small class="mx-1" color="blue-grey" size="26">
                      {{
                        items
                          .map((x) => x.noContainers)
                          .reduce((a, b) => a + b, 0)
                      }}
                    </v-chip>

                    Containers -
                    <v-chip x-small class="mx-1" color="blue-grey" size="26">
                      {{
                        items.map((x) => x.noPallets).reduce((a, b) => a + b, 0)
                      }}
                    </v-chip>

                    Pallets -

                    <v-chip
                      class="mx-1"
                      :color="
                        items
                          .map((x) => x.documents.complete)
                          .reduce((a, b) => a + b, 0) ==
                        items
                          .map((x) => x.documents.total)
                          .reduce((a, b) => a + b, 0)
                          ? 'green'
                          : 'blue'
                      "
                      x-small
                    >
                      {{
                        items
                          .map((x) => x.documents.complete)
                          .reduce((a, b) => a + b, 0)
                      }}
                      /
                      {{
                        items
                          .map((x) => x.documents.total)
                          .reduce((a, b) => a + b, 0)
                      }}
                    </v-chip>
                    Documents
                  </v-chip>
                </th> -->
                <th colspan="2" class="text-left" style="color: grey">
                  <v-chip small outlined style="border: none">
                    <v-chip x-small class="mx-1" left color="blue-grey">
                      {{
                        items
                          .map((x) => x.noContainers)
                          .reduce((a, b) => a + b, 0)
                      }}
                    </v-chip>

                    Containers
                    </v-chip>
</th>
<th colspan="2" class="text-left" style="color: grey">
  <v-chip small outlined style="border: none">
    <v-chip x-small class="mx-1" color="blue-grey" size="26">
                      {{
                        items.map((x) => x.noPallets).reduce((a, b) => a + b, 0)
                      }}
                    </v-chip>

                    Pallets
                    </v-chip>
</th>

<th colspan="2" class="text-left" style="color: grey">
  <v-chip small outlined style="border: none">
    <v-chip x-small class="mx-1" color="blue-grey" size="26">
                      {{
                        items.map((x) => x.noCartons).reduce((a, b) => a + b, 0)
                      }}
                    </v-chip>

                    Units
                    </v-chip>
</th>


<th colspan="2" class="text-left" style="color: grey">
  <v-chip small outlined style="border: none">
    <v-chip
                      class="mx-1"
                      :color="
                        items
                          .map((x) => x.documents.complete)
                          .reduce((a, b) => a + b, 0) ==
                        items
                          .map((x) => x.documents.total)
                          .reduce((a, b) => a + b, 0)
                          ? 'green'
                          : 'blue'
                      "
                      x-small
                    >
                      {{
                        items
                          .map((x) => x.documents.complete)
                          .reduce((a, b) => a + b, 0)
                      }}
                      /
                      {{
                        items
                          .map((x) => x.documents.total)
                          .reduce((a, b) => a + b, 0)
                      }}
                    </v-chip>

                    Documents
                    </v-chip>
</th>

                <th colspan="3" class="text-left"></th>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <v-chip :color="getStatusColor(item.status)" outlined small>
                  <v-icon left small class="mr-1">{{
                    getStatusIcon(item.status)
                  }}</v-icon>
                  {{ item.status }}</v-chip
                >
              </template>

              <template v-slot:[`item.arrivalWeek`]="{ item }">
                <span v-if="item.eta">{{ formatWeek(item.eta) }} </span>
              </template>

              <template v-slot:[`item.portOfLoadValue`]="{ item }">
                <v-tooltip top v-if="item.portOfLoadValue">
                  <template v-slot:activator="{ on }">
                    <v-chip pill outlined v-on="on" small>
                      <v-avatar size="24" left>
                        <v-img
                          contain
                          :src="`https://cdn.loglive.io/flags/4x3/${item.portOfLoadValue
                            .substring(0, 2)
                            .toLowerCase()}.svg`"
                        ></v-img>
                      </v-avatar>
                      {{ item.portOfLoadValue }}
                    </v-chip>
                  </template>
                  <span style="font-size: 12px">{{ item.portOfLoadCity }}</span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.finalDestinationValue`]="{ item }">
                <v-tooltip top v-if="item.finalDestinationValue">
                  <template v-slot:activator="{ on }">
                    <v-chip pill outlined v-on="on" small>
                      <v-avatar size="24" left>
                        <v-img
                          contain
                          :src="`https://cdn.loglive.io/flags/4x3/${item.finalDestinationValue
                            .substring(0, 2)
                            .toLowerCase()}.svg`"
                        ></v-img>
                      </v-avatar>
                      {{ item.finalDestinationValue }}
                    </v-chip>
                  </template>
                  <span style="font-size: 12px">{{
                    item.finalDestinationCity
                  }}</span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.documents`]="{ item }">
                <v-chip
                  v-if="
                    item.documents.complete > 0 || item.documents.incomplete > 0
                  "
                  x-small
                  :color="
                    item.documents.complete == item.documents.total
                      ? 'green'
                      : 'blue'
                  "
                >
                  {{ item.documents.complete }} / {{ item.documents.total }}
                </v-chip>
              </template>
              <template v-slot:[`item.products`]="{ item }">
                <ul class="ml-0 pl-0">
                  <li
                    v-for="product in item.products.filter((x) => x.product)"
                    :key="product.id"
                    style="font-size: 11px"
                  >
                    {{ $Format.capitalizeFirstLetter(product.product.name) }}
                  </li>
                </ul>
              </template>

              <template v-slot:[`item.references`]="{ item }">
                <ul v-if="item.references.length <= 5" class="ml-0 pl-0">
                  <li
                    v-for="(ref, index) in item.references"
                    :key="index"
                    style="font-size: 11px"
                  >
                    {{ ref }}
                  </li>
                </ul>
                <ul v-else class="ml-0 pl-0">
                  <li
                    v-for="(ref, index) in item.references.slice(0, 5)"
                    :key="index"
                    style="font-size: 11px"
                  >
                    {{ ref }}
                  </li>
                  <span style="font-size: 11px"
                    >+ {{ item.references.length - 5 }} Other(s)</span
                  >
                </ul>
              </template>

              <template v-slot:[`item.vessel`]="{ item }">
                <span>{{ item.vesselName }} {{ item.voyage }}</span>
              </template>
              <!-- <template v-slot:[`item.etd`]="{ item }">
                                    <span v-if="item.atd" color="orange">{{ item.atd }}</span>
                                    <span v-else color="orange">{{ item.etd }}</span>
                                </template>
                                <template v-slot:[`item.eta`]="{ item }">
                                    <span v-if="item.ata" color="orange">{{ item.ata }}</span>
                                    <span v-else color="orange">{{ item.eta }}</span>
                                </template> -->
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog v-model="infoStart" max-width="31vw">
      <v-card>
        <v-btn
          @click="infoStartGuide"
          icon
          style="position: absolute; right: 0; margin: 0.6vh 1vh 0 0"
          ><v-icon color="primary" size="30">close</v-icon></v-btn
        >
        <v-card-title class="d-flex justify-center">
          FRIKO Welcome to Shipment Overview!
        </v-card-title>
        <v-card-text>
          <span>
            <p>FRIKO</p>
            <p>
              FRIKO <br />• Map out routes for transporters <br />• Add GeoJSON
              data to locations used determine if a vehicle is within a certain
              area <br />• Calculate accurate waiting times at loading points
              <br />• Calculate distances for billing purposes <br />• Pin-point
              locations for the purpose of reporting and analysis <br />• Map
              out farms that aren't found on Google Maps
            </p>
            If you would like learn how to load POI's into the system, click the
            button below to start the tutorial.
          </span>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn @click="startGuide" color="edit" class="mb-3">Start!</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import dateFormat from "dateformat";
import Driver from "driver.js";
import "driver.js/dist/driver.min.css";
// import steps from "./Steps/shipmentSteps";
import * as moment from "moment";
export default {
  props: ["selectedCustomer"],
  data: () => ({
    // guides
    steps: null,
    
    availableYears: [],
    filters: {},
    filterKey: 2000,
    filteredResults: [],
    headerKey: 1000,
    headers: [
      {
        text: "File Number",
        value: "fileNumber",
        align: "center",
      },
      {
        text: "Cancelled",
        value: "cancelled",
        align: "center",
      },
      {
        text: "Linked Bookings",
        value: "bookings",
        align: "center",
      },
      {
        text: "No. Containers",
        value: "containers",
        align: "center",
      },
      {
        text: "Vessel/Voyage",
        value: "vessel",
        align: "center",
      },
      {
        text: "POL",
        value: "pol",
        align: "center",
      },
      {
        text: "POD",
        value: "pod",
        align: "center",
      },
    ],
    shipmentHeaders: [
      {
        text: "Status",
        value: "status",
        align: "center",
        filterable: true,
      },
      {
        text: "Arrival Week",
        value: "arrivalWeek",
        width: "20px",
        align: "center",
      },
      {
        text: "Carrier",
        value: "carrier",
        sortable: false,
        align: "center",
        filterable: true,
      },

      // {
      //     text: "Shipper",
      //     value: "shipper.name",
      //     sortable: false,
      //     width: "180px",
      //     align: "left",
      // },
      {
        text: "Receiver",
        value: "consignee.name",
        sortable: false,
        width: "180px",
        align: "left",
      },
      // {
      //   text: "Vessel/Voyage",
      //   value: "vessel.name",
      //   sortable: false,
      //   align: "center",
      // },
      {
        text: "POL",
        value: "portOfLoadValue",
        sortable: false,
        align: "center",
        filterable: true,
      },
      {
        text: "ETD/ATD",
        value: "etd",
        sortable: false,
        align: "center",
        width: "120px",
      },
      {
        text: "Final Dest.",
        value: "finalDestinationValue",
        sortable: false,
        align: "center",
        filterable: true,
      },

      {
        text: "ETA/ATA",
        value: "eta",
        width: "120px",
        sortable: false,
        align: "center",
      },

      {
        text: "Products",
        value: "products",
        align: "left",
        sortable: false,
      },
      {
        text: "References",
        value: "references",
        align: "left",
        sortable: false,
      },
      {
        text: "Containers",
        value: "noContainers",
        align: "center",
      },
      {
        text: "No. Pallets",
        value: "noPallets",
        align: "center",
      },
      {
        text: "Documents",
        value: "documents",
        align: "center",
      },
    ],
    loadingConsigneeInstruction: false,
    loadingFiles: false,
    loadingProfiles: false,
    filePage: 1,
    fileParams: {
      limit: 12,
      offset: 0,
      search: null,
    },
    loading: false,
    page: 1,
    params: {
      limit: 15,
      offset: 0,
      search: null,
    },
    profiles: [],
    search: null,
    shipments: {
      total: 0,
      data: [],
    },
    shipment: {},
    shipmentDialog: false,
    shipmentFileDialog: false,
    shipmentFiles: {
      total: 0,
      data: [],
    },
    infoStart: false,
  }),
  watch: {
    "selectedCustomer.relatedOrganisationId": {
      immediate: true,
      handler(val) {
        if (val) {
          // this.getCustomerShipments()
          this.getOverviewShipmentsByCustomer(this.selectedCustomer.id);
          // this.getConsigneeProfiles();
          //   this.getRelatedOrganisations();
        }
      },
    },
    // headerKey(){

    // }
    // async page(value) {
    //   this.params.offset = (value - 1) * this.params.limit;
    //   await this.getCustomerShipments();
    // },
    // "params.limit": {
    //   immediate: true,
    //   async handler() {
    //     await this.getCustomerShipments();
    //   },
    // },
    // filters(val){
    //   console.log(val)
    // }
  },
  computed: {
    filteredDetails() {
      let result = [...this.filteredResults];
      if (this.search) {
        result = result.filter(
          (x) =>
            x.references.some((y) =>
              y.toLowerCase().includes(this.search.toLowerCase())
            ) ||
            (x.shipper &&
              x.shipper.name
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.consignee &&
              x.consignee.name
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            x.vessel.name.toLowerCase().includes(this.search.toLowerCase()) ||
            x.voyage.toLowerCase().includes(this.search.toLowerCase()) ||
            x.products.some((y) =>
              y.product.name.toLowerCase().includes(this.search.toLowerCase())
            )
        );
      }
      return result;
    },
  },
  async created() {
    await this.getSteps()
  },
  async mounted() {
    this.driver = new Driver({
      animate: false,
    });
    this.filters = {};
    let keys = this.shipmentHeaders.map((x) => x.value);
    keys.forEach((x) => {
      this.filters[x] = [];
    });
  },
  methods: {
    async getSteps() {
        let steps = await this.$API.getGuideSteps({
            guideId: 'Contacts/Shipment/Steps',
        });
        this.steps = steps.steps
    },
    calculateContainers(bookings) {
      return bookings
        .map((item) => item.bookingContainers.length)
        .reduce((prev, next) => prev + next);
    },
    async addShipment(item) {
      this.shipment = {
        shipmentFileId: item.id,
        consigneeProfileId: null,
      };
      this.createShipment();
      //   this.shipmentDialog = true;
    },
    async createShipment() {
      this.shipment.consigneeProfileId = this.shipment.consigneeProfile;
      let result = await this.$API.createShipment(this.shipment);
      this.shipmentDialog = false;
      this.$router.push({
        path: "/shipment/" + result.id,
      });
    },
    calculateFilteredResults() {
      let result = [...this.shipments.data];
      // console.log(result.length)
      // if (this.search) {
      //   result = result.filter(x =>
      //     x.references.some(y => y.toLowerCase().includes(this.search.toLowerCase())) ||
      //     (x.shipper && x.shipper.name.toLowerCase().includes(this.search.toLowerCase())) ||
      //     (x.consignee && x.consignee.name.toLowerCase().includes(this.search.toLowerCase())) ||
      //     x.vessel.name.toLowerCase().includes(this.search.toLowerCase()) ||
      //     x.voyage.toLowerCase().includes(this.search.toLowerCase()) ||
      //     x.products.some(y => y.product.name.toLowerCase().includes(this.search.toLowerCase()))
      //   )
      // }
      let keys = Object.keys(this.filters);
      // for(let i=0;i<keys.length;i++){
      //   if(this.filters[keys[i]].length > 0){
      //     result = result.filter(x => this.filters[keys[i]].includes(x[keys[i]]))
      //   }
      // }
      for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        if (this.filters[key] && this.filters[key].length > 0) {
          result = result.filter((x) => this.filters[key].includes(x[key]));
        }
      }
      // Object.keys(this.filters).forEach(key => {
      //   if(this.filters[key].length > 0){
      //     result = result.filter(x => this.filters[key].includes(x[key]))
      //   }
      // })
      this.filteredResults = result;
    },
    filteredContainers(status) {
      let data = this.filteredResults.filter((x) => x.status === status);
      return data.map((x) => x.noContainers).reduce((a, b) => a + b, 0);
    },
    filteredPallets(status) {
      let data = this.filteredResults.filter((x) => x.status === status);
      return data.map((x) => x.noPallets).reduce((a, b) => a + b, 0);
    },
    formatWeek(date) {
      return date
        ? moment(new Date(date)).isoWeek() +
            " - " +
            moment(new Date(date)).format("YYYY")
        : null;
    },
    formatDate(date) {
      let result = null;
      if (date) {
        result = dateFormat(new Date(date), "dd mmm, yyyy HH:MM");
      }
      return result;
    },
    getStatusColor(status) {
      switch (status) {
        case "Awaiting Departure":
          return "orange";
        case "In Transit":
          return "blue";
        case "Arrived":
          return "green";
        case "Cancelled":
          return "red";
        default:
          return "darkgrey";
      }
    },
    getStatusIcon(status) {
      switch (status) {
        case "Awaiting Departure":
          return "location_on";
        case "In Transit":
          return "mode_of_travel";
        case "Arrived":
          return "where_to_vote";
        case "Cancelled":
          return "close";
        default:
          return "info";
      }
    },
    async getConsigneeProfiles() {
      (this.loadingProfiles = true),
        (this.profiles = await this.$API.listCustomerConsigneeProfile(
          this.selectedCustomer.relatedOrganisationId,
          {}
        ));
      this.loadingProfiles = false;
    },
    async getOverviewShipmentsByCustomer(id) {
      this.loading = true;
      this.shipments = await this.$API.getOverviewShipmentsByCustomer(id, {
        params: this.params,
      });
      this.availableYears = this.shipments.availableYears;
      if (!this.shipments.availableYears.includes(this.params.selectedYear)) {
        this.params.selectedYear = this.shipments.currentYear;
      }
      setTimeout(() => {
        Object.keys(this.$refs.table.openCache).forEach(
          (g) => (this.$refs.table.openCache[g] = false)
        );
      }, 500);
      this.calculateFilteredResults();
      this.loading = false;
    },
    async getCustomerShipments() {
      this.loading = true;
      this.shipments = await this.$API.getCustomerShipments(
        this.selectedCustomer.relatedOrganisationId,
        {
          params: this.params,
        }
      );
      this.loading = false;
    },
    async getShipmentFiles() {
      this.loadingFiles = true;
      this.shipmentFiles = await this.$API.listShipmentFile({
        params: this.fileParams,
      });
      this.loadingFiles = false;
    },
    formatHeaderName(value, field, data) {
      switch (field) {
        case "vessel.name":
          return data.vessel.name;
        case "consignee.name":
          return data.consignee.name;
        default:
          return value;
      }
    },
    headerOptions(value) {
      let result = [
        ...new Set(
          this.shipments.data
            .filter((x) => x[value])
            .map((x) => ({
              name: this.formatHeaderName(x[value], value, x),
              value: x[value],
            }))
        ),
      ];
      let uniqueValues = [...new Set(result.map((x) => x.value))];
      result = uniqueValues.map((x) => result.find((y) => y.value === x));
      // console.log(value, result)
      return result;
    },
    // getStatusColor(status) {
    //   switch (status) {
    //     case "Open":
    //       return "blue";
    //     case "Draft":
    //       return "orange";
    //     case "Complete":
    //       return "green";
    //   }
    // },
    // viewShipment(item) {
    //   this.$router.push({
    //     path: "/shipment/" + item.shipmentReference,
    //   });
    // },
    viewShipment(item) {
      this.$router.push({
        path: "/shipment/overview/" + item.hash,
        query: {
          status: item.status,
          vesselId: item.vesselId,
          vessel: item.vessel.name,
          voyage: item.voyage,
          vesselVoyageId: item.vesselVoyageId,
          shipper: item.shipper.name,
          shipperId: item.shipperId,
          consignee: item.consignee.name,
          consigneeId: item.consigneeId,
          pol: item.portOfLoadValue,
          pod: item.finalDestinationValue,
          arrivalWeek: item.arrivalWeek,
          etd: item.etd,
          eta: item.eta,
          noContainers: item.noContainers,
          noPallets: item.noPallets,
          noCartons: item.noCartons,
        },
      });
    },
    infoStartGuide() {
      if (this.infoStart) {
        this.infoStart = false;
      } else {
        this.infoStart = true;
      }
    },
    selectYear(year) {
      this.params.selectedYear = year;
      this.getOverviewShipmentsByCustomer(this.selectedCustomer.id);
    },
    startGuide(e) {
      this.infoStart = false;
      e.stopPropagation();
      this.driver.defineSteps(this.steps);
      this.driver.start();
    },
  },
};
</script>
  